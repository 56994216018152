import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class QuoteSection extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <Container fluid className={this.props.grey ? "backgroundOff" : ""}>
        <Container>
          <Row className="align-items-center fwdSection">
            <Col>
              <h1 className="text-center topAndBottomPadding">"Nearly three quarters of Canadians have debt"</h1>
              <h4 className="text-center topAndBottomPadding">- 2019 Canadian Financial Capability Survey</h4>
              <p style={{margin: "0"}} className="text-center topAndBottomPadding">Almost a third of that number believe they have too much debt. Don't let your debt keep you down.</p>
              <p className="text-center topAndBottomPadding"><b>MyDebt</b> can help you <u>understand</u> your situation, and build a <u>plan</u> towards a debt free life.</p>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default QuoteSection;