import React from 'react';
import HeaderSection from './header';
import Cards from './infoCards';
import ScenarioAnalysis from './scenarioAnalysis';
import Subscribe from './subscribe';
import DebtSummary from './debtSummary';
import QuoteSection from './debtQuote';

class FwdBody extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeaderSection grey={false} />
        <QuoteSection grey={true} />
        {/* <Cards grey={true} /> */}
        <DebtSummary grey={false} />
        <ScenarioAnalysis grey={true} />
        <Subscribe grey={false} />
      </React.Fragment>
    );
  }
}

export default FwdBody;