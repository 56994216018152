import React from 'react';
import Container from 'react-bootstrap/Container';

class Privacy extends React.Component {
  componentDidMount() {
    document.title = 'Privacy Policy | FWD Financial';
  }

  render() {
    return (
      <React.Fragment>
        <Container id="privacy" style={{padding: "76px 8px"}}>
          <h1>Privacy Policy</h1>
          {/* <h5>1. Notice for European Union Residents</h5>
          <p>This Service is primarily intended for residents of Canada. If you are a resident of the European Union (“EU”) and you purchase a Service or are otherwise subject to our TOS, you have additional privacy rights discussed in the Privacy Policy Addendum for Residents of the European Union, described LINKHERE</p>
          <h5>2. Notice to Residents of the State of California</h5>
          <p>If you are a resident of the State of California, in the United States of America, and you purchase a Service or otherwise subject to our TOS, you have additional privacy rights described LINKHERE</p> */}
          <h5>1. Your privacy</h5>
          <ol>
            <li>At Fwd Financial (2198893 Alberta Ltd.), your privacy is, and always has been, very important to us. We are dedicated to providing you with superior service while protecting your privacy and safeguarding your personal information.</li>
            <li>“Personal Information” is defined in <i>Canada’s Personal Information Protection and Electronic Documents Act, S.C. 2000, c. 5 (“PIPEDA”)</i>, as information about an identifiable individual, though it does not include the name, business title, business address or telephone number of employees of organizations.</li>
          </ol>
          <h5>2. What we do with your information</h5>
          <p>Collecting Personal information about you is essential to our being able to provide the products and services that best meet the needs of both yourself and/or authorized third parties (“Client”). While the personal information we collect may come directly from you, it may also be provided by our affiliates or other third parties (such as employers, insurance companies, insurance brokers or agents, credit organizations, motor vehicle and driver licensing authorities, financial institutions, medical professionals, etc.). We do not sell or share your Personal Information with third parties for their own commercial uses without your consent.</p>
          <h5>3. Types of information we collect</h5>
          <p>In connection with accessing our Services, we may collect information from you which can be used to identify you (“Personal Information”), such as your name, shipping/billing address, email address, phone, username and password.</p>
          <p>We collect information when you register or open an account on the App, sign in, input information, call us for support, or give us feedback. We may also collect content or other information that you may provide or create when you interact with our App.</p>
          <p>We may also automatically collect certain usage information when you access our App (“Usage Data”), such as Internet Protocol (“IP”) addresses, log files, unique device identifiers, pages viewed, any links you click on to leave or interact with our Services, and other usage information collected from cookies and other tracking technologies.</p>
          <p>For example, we collect IP addresses to track and aggregate non-personal information, such as using IP addresses to monitor the regions from which users navigate to our Services. We also collect IP addresses from users when they log into the Services as part of our log-in and security features. We may also, when you enable location-based Services, collect Global Positioning System (GPS) location data and/or motion data.</p>
          <p>Our App may change over time and we may introduce new features that may collect new or different types of information.</p>
          <h5>4. How we use your information</h5>
          <p>We may use your information, including Personal Information, for the following purposes:</p>
          <ul>
            <li><i>Account Registration.</i> We may use your name, address, phone number and email address to register your account for certain Services we provide and to communicate important information to you.</li>
            <li><i>To Provide Our Services and Operate Our Business.</i> We may use your information to operate our business, including providing Services you requested, provide you with support related to our Services, and to help us protect our Services, including to combat fraud and protect your information.</li>
            <li><i>Customer Service and Technical Support.</i> We may use your name, address, phone number, email address, and how you interact with our App, to resolve questions you may have about our App and to follow up with you about your experience.</li>
            <li><i>Communicate with You and Tell You About Other Services.</i> We may use your information to communicate with you about our app and updates on future releases or features.</li>
            <li><i>To Improve our App.</i> We will use your information to personalize or customize your experience, develop new features, and to improve the overall quality of the App.</li>
            <li><i>Feedback.</i> We may use any information you volunteer in surveys you answer for us and combine them with answers from other customers in order to better understand our App and how we may improve it. Answering any survey is optional.</li>
          </ul>
          <h5>5. Consent to use Personal Information</h5>
          <p>Providing us with your personal information is always your choice. When you use our App, you consent to our collection, use and disclosure to appropriate third parties of such personal information for these purposes. You also authorize us to use and retain this personal information for as long as it may be required for the purposes described above. Your consent remains valid even after the termination of our relationship with you, unless you provide us with written notice that such consent is withdrawn.</p>
          <h5>6. Sharing your Personal Information with others</h5>
          <ol>
            <li>We are not in the business of selling client lists or personal information to others. In providing our App, we may need to disclose personal information we collect to affiliates, subsidiaries, successors and other service providers or agents who perform various functions for us.</li>
            <li>As Fwd Financial (2198893 Alberta Ltd.) continues to develop and grow, we may buy or sell parts of our business. As our businesses consist primarily of client relationships, information regarding the particular accounts or services being purchased or sold could include personal information and be one of the transferred business assets.</li>
            <li>In certain circumstances, we may be required to provide personal information to third parties for legal or regulatory purposes.</li>
            <li>We may also use this personal information to assess your future needs and to offer the products and services that may best meet those needs, from ourselves, our affiliates or reputable organizations selected by us. If you do not wish to receive these offers or such information, please contact our Privacy Officer as outlined below in the contact information section.</li>
            <li>Other than as set out above, we will provide you with notice and the opportunity to choose when your Personal Information may be shared with other third parties.</li>
          </ol>
          <h5>7. How we safeguard Personal Information</h5>
          <ol>
            <li>At FWD Financial, we employ physical, electronic and procedural safeguards to protect our systems and all personal information under our control against unauthorized access and use. All safety and security measures are appropriate to the sensitivity level of the information collected.</li>
            <li>Our service providers and agents are required to maintain Client confidentiality, and may not use the information for any unauthorized purpose.</li>
            <li>Employees are governed by strict standards and policies to ensure that personal information is secure and treated with the utmost care and respect.</li>
          </ol>
          <h5>8. Data retention</h5>
          <p>In accordance with and as permitted by applicable law and regulations, we will retain your information as long as otherwise needed to operate our business. When you close your account, we may continue to communicate with you about our App and let you know about products and services that may interest you, unless you have opted out of receiving marketing communications. We may also continue to use some of your information for business purposes and to improve our offerings or in some cases to develop new ones. We will retain and use your information as required by applicable regulations to comply with our legal and reporting obligations, resolve disputes, enforce our agreements, complete and outstanding transactions and for the detection and prevention of fraud.</p>
          <h5>9. Disclosure to third-party processors, processing in U.S.</h5>
          <p>We use third-party services providers to operate, provide, and maintain some aspects of our website, and we may request and permit these service providers to process your user data under the terms of a written agreement with us. Under such agreements, the service provider is only permitted to obtain the user data it needs to deliver the service, must keep all user data confidential, and may process, use and retain user data only for the purpose of delivering the service in compliance with our agreement, instructions and policies, including this Privacy Policy. Third-party data processors are located in the United States, so user data is collected, stored and processed in the U.S.</p>
          <h5>10. Transfer of data outside of Canada</h5>
          <p>Your Personal Data will be held in Canada. Your Personal Data may also be stored, processed and accessed in other countries than where you are located. You consent to the transfer of your Personal Data outside your country, including Canada.</p>
          <h5>11. Changes to our privacy policy</h5>
          <p>From time to time we may change or update our Privacy Policy. We reserve the right to make changes or updates at any time. If we make material changes to the way we process your Personal Information, we will provide you notice by email or community post. Please review any changes carefully. If you object to any of the changes and no longer wish to use our App, you may close your account(s). All changes are effective immediately upon posting and your use of our App after a notice of material change or posting of an updated Privacy Policy shall constitute your consent to all changes.</p>
          <h5>12. Contact information</h5>
          <p>Please contact our Privacy Officer to obtain further information about our policies and procedures or if you have any unresolved enquiries or concerns. Our Privacy Officer can be contacted as follows:</p>
          <p>2198893 Alberta LTD.</p>
          <p>201-4702 1 St SW, Calgary, AB T2G 0A2</p>
          <p>E-mail: <a href="mailto:hello@fwdfinancial.io">hello@fwdfinancial.io</a></p>
          <p><i>Last Revised: June 2020</i></p>
        </Container>
      </React.Fragment>
    );
  }
}

export default Privacy;