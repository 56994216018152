import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import logo from '../assets/logo.png';
import linkedin from '../assets/linkedin.svg';
import instagram from '../assets/instagram.svg';
import tiktok from '../assets/tiktok.svg';
import twitter from '../assets/twitter.svg';
import { Link } from "react-router-dom";
import google from '../assets/play-store-badge.png';
import apple from '../assets/app-store-badge.png';

class FwdFooter extends React.Component {
  render() {
    return (
      <Container fluid className="bg-dark" id="footer">
        <Container style={{padding: "48px 16px"}}>
          <Row>
            <Col className="text-center">
              <img
                alt="FWD Financial MyDebt"
                className="fwdLogo"
                src={logo}
              />
            </Col>
            <Col className="contactUs" style={{paddingTop: "16px"}}>
              <p style={{margin: "0px"}}><b>Contact Us</b></p>
              <a href="mailto:hello@fwdfinancial.io">Hello@fwdfinancial.io</a>
            </Col>
            <Col className="text-center">
              <a href={"https://apps.apple.com/ca/app/mydebt/id1511205793"}>
                <img 
                  alt="MyDebt Apple App Store"
                  src={apple}
                  width="160"
                  height="70"
                  style={{margin: "8px", paddingTop: "16px"}}
                />
              </a>
              <a href={"https://play.google.com/store/apps/details?id=fwd_debt_prototype.app&hl=en"}>
                <img 
                  alt="MyDebt Google Play Store"
                  src={google}
                  width="183"
                  height="70"
                  style={{margin: "8px", paddingBottom: "16px"}}
                />
              </a>
            </Col>
          </Row>
          <Row className="justify-content-center" style={{padding: "16px"}}>
            <Col className="text-center">
              <a href="https://www.linkedin.com/company/fwd-financial/about/" class="btn-icon">
                <img src={linkedin} alt="MyDebt Linkedin" height="35" />
              </a>
            </Col>
            <Col className="text-center">
              <a href="https://www.instagram.com/fwdfinancial/?hl=en" class="btn-icon">
                <img src={instagram} alt="MyDebt Instagram" height="35" />
              </a>
            </Col>
            <Col className="text-center">
              <a href="https://vm.tiktok.com/q2MmTn/" class="btn-icon">
                <img src={tiktok} alt="myDebt Tiktok" height="35" />
              </a>
            </Col>
            <Col className="text-center">
              <a href="https://twitter.com/FWDFinancialApp" class="btn-icon">
                <img src={twitter} alt="MyDebt Twitter" height="35" />
              </a>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <p style={{margin: "0px", paddingTop: "16px"}}>© 2020 Fwd Financial. All rights reserved. | <Link to="/privacy">Privacy</Link> | <Link to="/terms">Terms of Service</Link></p>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default FwdFooter;