import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import mobile from '../../assets/FWD_iPhone1.png';
import ScrollReveal from 'scrollreveal';

class DebtSummarySection extends React.Component {
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    const config = {
      origin: 'left',
      duration: 1000,
      delay: 150,
      distance: '500px',
      scale: 1,
      easing: 'ease',
    };

    ScrollReveal().reveal('.scenarioImage', config);
  }

  render() {
    return (
      <Container fluid className={this.props.grey ? "backgroundOff" : ""}>
        <Container>
          <Row className="align-items-center">
            <Col>
              <Row className="justify-content-center">
                <img 
                  src={mobile}
                  alt="MyDebt Summary Dashboard"
                  className="mobileImage scenarioImage"
                  style={{margin: "24px"}}
                />
              </Row>
            </Col>
            <Col className="justify-content-center align-items-center row">
              <h1>Analyze your Debt</h1>
              <p>Keep a tab on your debts all in one place.</p>
              <ul style={{textAlign: "left"}}>
                <li>Input and view debts in a central location</li>
                <li>Visualize your current situation</li>
                <li>Identify your monthly commitment to debt repayment</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default DebtSummarySection;