import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import mobile from '../../assets/FWD_iPhone3.png';
import ScrollReveal from 'scrollreveal';

class ScenarioAnalysisSection extends React.Component {
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    const config = {
      origin: 'right',
      duration: 1000,
      delay: 150,
      distance: '100px',
      scale: 1,
      easing: 'ease',
      mobile: false
    };

    ScrollReveal().reveal('.summaryImage', config);
  }

  render() {
    return (
      <Container fluid className={this.props.grey ? "backgroundOff" : ""}>
        <Container>
          <Row className="align-items-center">
            <Col className="justify-content-center align-items-center row">
              <h1>Scenario Analysis</h1>
              <p>Small changes can make big impacts.</p>
              <ul style={{textAlign: "left"}}>
                <li>Visualize the effect of increased monthly payments</li>
                <li>Add a large payment and watch interest shrink</li>
                <li>Pick a target date to pay off a debt by</li>
                {/* <li>Pick a target date to pay off a debt by</li> */}
              </ul>
            </Col>
            <Col>
              <Row className="justify-content-center">
                <img 
                  src={mobile}
                  alt="MyDebt Scenario Analysis"
                  className="mobileImage summaryImage"
                  style={{margin: "24px"}}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default ScenarioAnalysisSection;