import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import mobile from '../../assets/FWD_iPhone5.png';
import google from '../../assets/play-store-badge.png';
import apple from '../../assets/app-store-badge.png';
import ScrollReveal from 'scrollreveal';

class HeaderSection extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const config = {
      origin: 'left',
      duration: 500,
      delay: 150,
      distance: '500px',
      scale: 1,
      easing: 'ease',
    };
    const configLeft = {
      origin: 'left',
      duration: 500,
      delay: 150,
      distance: '500px',
      scale: 1,
      easing: 'ease',
    };

    ScrollReveal().reveal('.dashboardImage', config);
    ScrollReveal().reveal('.revealLeft', configLeft);
  }

  render() {
    return (
      <Container fluid className={this.props.grey ? "backgroundOff" : "freedomBackground"}>
        <Container style={{padding: "76px 16px"}}>
          <Row className="align-items-center revealLeft">
            <Col style={{minWidth: "300px"}}>
              <h1 style={{color: "white"}}>Take control with MyDebt</h1>
              <p style={{color: "white"}}>The easiest way to understand your debt. Input your loans and let MyDebt show you your future.</p>
              <Row className="justify-content-center">
                <a href={"https://apps.apple.com/ca/app/mydebt/id1511205793"}>
                  <img 
                    src={apple}
                    alt="MyDebt Apple App Store"
                    width="160"
                    height="54"
                    style={{margin: "8px"}}
                  />
                </a>
                <a href={"https://play.google.com/store/apps/details?id=fwd_debt_prototype.app&hl=en"}>
                  <img 
                    src={google}
                    alt="MyDebt Google Play Store"
                    width="183"
                    height="54"
                    style={{margin: "8px"}}
                  />
                </a>
              </Row>
            </Col>
            <Col>
              <Row className="justify-content-center dashboardImage">
                <img 
                  className="mobileImage"
                  alt="FWD Financial"
                  src={mobile}
                  style={{margin: "24px"}}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default HeaderSection;