import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ScrollReveal from 'scrollreveal';
import * as firebase from 'firebase';

class SubscribeSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      subscribed: false
    }
  }

  componentDidMount() {
    const config = {
      origin: 'bottom',
      duration: 1000,
      delay: 150,
      distance: '500px',
      scale: 1,
      easing: 'ease',
    };

    ScrollReveal().reveal('.revealUp', config);
  }

  changed = (event) => {
    this.setState({
      email: event.target.value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    firebase.firestore().collection('signups').add({
      email: this.state.email,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    }).catch((error) => {
      console.error('Error writing new message to database', error);
    });
    this.setState({
      email: "",
      subscribed: true
    });
  }

  render() {
    return (
      <Container fluid className={this.props.grey ? "backgroundOff" : ""}>
        <Container>
          <Row className="align-items-center justify-content-center fwdSection2 revealUp">
            <Col className="cardWidth">
              <Card className="card border-light mb-3 shadow-lg">
                <Card.Header><b>Subscribe</b></Card.Header>
                <Card.Body>
                  <Card.Text>Receive information and updates on MyDebt and our progress!</Card.Text>
                    {this.state.subscribed ? <p style={{color: "#1B9D2C"}}>Great. You've signed up!</p> : <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                      <Col style={{paddingTop: "4px", paddingBottom: "4px"}}>
                        <Form.Control placeholder="name@email.com" type="email" value={this.state.email} onChange={this.changed} />
                      </Col>
                      <Col lg="3" style={{paddingTop: "4px", paddingBottom: "4px"}}>
                        <Button className="btn-block" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </Form.Row>
                  </Form>}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default SubscribeSection;