import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import playLogo from '../assets/fwdLogo.png';
import financialLettering from '../assets/FwdFinancialLettering.png';
import Container from 'react-bootstrap/Container';

class FwdNavBar extends React.Component {
  render() {
    return (
      <div>
        <Container fluid>
          <Navbar bg="light" fixed="top">
            <Navbar.Brand href="/">
              <img
                src={playLogo}
                alt="Fwd Financial Logo"
                className="d-inline-block navImageLogo"
              />
              <img
                src={financialLettering}
                alt="Fwd Financial"
                className="d-inline-block navImageLetters justify-content-end"
                height="15"
                style={{paddingLeft: "8px"}}
              />
            </Navbar.Brand>
          </Navbar>
        </Container>
      </div>
    );
  }
}

export default FwdNavBar;