import React from 'react';
import Container from 'react-bootstrap/Container';
import CardDeck from 'react-bootstrap/CardDeck'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ScrollReveal from 'scrollreveal';
import Insight from '../../assets/insight.png';
import Budget from '../../assets/budget.png';
import Scenario from '../../assets/scenario.png';

class InfoCardsSection extends React.Component {
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    const config = {
      origin: 'right',
      duration: 500,
      delay: 150,
      distance: '50%',
      scale: 1,
      easing: 'ease',
      mobile: false
    };

    ScrollReveal().reveal('.card1', config);
    ScrollReveal().reveal('.card2', config);
    ScrollReveal().reveal('.card3', config);
  }

  render() {
    return (
      <Container fluid className={this.props.grey ? "backgroundOff" : ""}>
        <Container>
          <Row className="align-items-center fwdSection">
            <Col>
              <h1 className="text-center topAndBottomPadding">Don't let your debt keep you down</h1>
              <CardDeck>
                <Card className="card border-light mb-3 shadow-lg card1">
                  <Card.Header>
                    <Row className="justify-content-center align-items-center">
                      <img
                        alt=""
                        src={Insight}
                        height={50}
                      />
                      <p style={{fontSize: "24px", padding: "12px", margin: "0px"}}><b>Understand</b></p>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title></Card.Title>
                    {/* <a href="https://www.canada.ca/en/financial-consumer-agency/programs/research/canadian-financial-capability-survey-2019.html">debt</a> */}
                    <Card.Text>Nearly three quarters of Canadians have debt. You're not alone.</Card.Text>
                  </Card.Body>
                </Card>
                <Card className="card border-light mb-3 shadow-lg card2">
                  <Card.Header>
                    <Row className="justify-content-center align-items-center">
                      <img
                        alt=""
                        src={Scenario}
                        height={50}
                      />
                      <p style={{fontSize: "24px", padding: "12px", margin: "0px"}}><b>Analyze</b></p>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>MyDebt can help you understand your current situation.</Card.Text>
                  </Card.Body>
                </Card>
                <Card className="card border-light mb-3 shadow-lg card3">
                  <Card.Header>
                    <Row className="justify-content-center align-items-center">
                      <img
                        alt=""
                        src={Budget}
                        height={50}
                      />
                      <p style={{fontSize: "24px", padding: "12px", margin: "0px"}}><b>Act</b></p>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>Gain the information to be able to handle your debt.</Card.Text>
                  </Card.Body>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default InfoCardsSection;